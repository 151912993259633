import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  textTransform: 'uppercase',
  color: theme.palette.navyBlue,
  padding: '0 8px',
}));

export default SectionTitle;
