import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Wrapper = styled(Box)({
  height: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export default Wrapper;
