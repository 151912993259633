import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const SidebarNavigationInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),
  marginTop: theme.spacing(3.37),
  justifyContent: 'space-between',
  height: '100%',
}));

export default SidebarNavigationInner;
