import { Box } from '@mui/material';
import { StyledCard } from 'app/components/StyledComponents/StyledCard';
import { SettingsMenuSection } from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/index';
import SectionTitle from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_components/SettingsMenuSectionItem/_styles/SectionTitle';
import SettingsMenuLink from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_components/SettingsMenuSectionItem/_components/SettingsMenuLink';

type Props = {
  menuSection: SettingsMenuSection;
};

const SettingsMenuSectionItem = ({ menuSection }: Props) => {
  return (
    <Box key={menuSection.title}>
      <StyledCard sx={{ p: 2 }}>
        <SectionTitle>{menuSection.title}</SectionTitle>

        {menuSection.links.map((link) => (
          <SettingsMenuLink key={link.name} item={link} />
        ))}
      </StyledCard>
    </Box>
  );
};

export default SettingsMenuSectionItem;
