import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Divider, ListItem } from '@mui/material';
import { SimpleDragButton } from 'app/components/Buttons/DragButton';

type OrderItemProps = {
  id: number | string;
  name: string;
};

export const OrderItem = ({ id, name }: OrderItemProps) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: Number(id),
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 100 : 1,
  };

  return (
    <>
      <ListItem {...attributes} ref={setNodeRef} style={style}>
        <SimpleDragButton {...listeners} /> {name}
      </ListItem>
      <Divider />
    </>
  );
};
