import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const OutletWrapper = styled(Box)({
  position: 'relative',
  flex: 1,
  width: '100%',
  height: '100%',
});

export default OutletWrapper;
