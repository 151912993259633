import React, { useEffect, useState } from 'react';
import { getConversationTokenCall } from 'api/hubspot';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { AppState } from 'types';
import { useScreenSize } from 'hooks/useScreenSize';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    hsConversationsSettings: any;
    HubSpotConversations: any;
  }
}

interface ConversationData {
  email: string;
  token: string;
}

const HubspotChatProvider = () => {
  const { isMobile } = useScreenSize();
  const [conversationData, setConversationData] = useState<ConversationData | false>(false);
  const { pathname } = useLocation();

  const hasAccessToHubspotChat = useSelector(
    (state: AppState) => state.auth.userSettings.permissions.accessHubspotChat,
  );

  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    getConversationTokenCall().then((data) => setConversationData(data));

    return () => window.HubSpotConversations?.widget.remove();
  }, []);

  useEffect(() => {
    const showChat =
      conversationData && hasAccessToHubspotChat && !isMobile && pathname.includes('/settings');

    if (showChat) {
      window.hsConversationsSettings = {
        identificationEmail: conversationData.email,
        identificationToken: conversationData.token,
      };

      window.HubSpotConversations?.widget?.load();
    } else {
      window.HubSpotConversations?.widget?.remove();
    }
  }, [isMobile, conversationData, hasAccessToHubspotChat, pathname]);

  return (
    <Helmet>
      <script src="//js.hs-scripts.com/4454236.js"></script>
    </Helmet>
  );
};

export default HubspotChatProvider;
