import { NavigationContext } from 'app/components/Layout/SettingsWrapper/_context/NavigationContext';
import { Suspense, useState } from 'react';
import { useScreenSize } from 'hooks/useScreenSize';
import { CustomBackdrop } from 'app/components/Overlays/CustomBackdrop';
import { Outlet } from 'react-router-dom';
import OutletWrapper from 'app/components/Layout/SettingsWrapper/_context/_styles/OutletWrapper';
import SettingsMenu, {
  SettingsMenuSection,
} from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu';

type Props = {
  menuItems: SettingsMenuSection[];
};

const SettingsWrapper = ({ menuItems }: Props) => {
  const [navigationValues, setNavigationValues] = useState({ visible: false });
  const { isMobile, isMax760 } = useScreenSize();
  const showMobileSubNavigation = isMobile ? !navigationValues.visible : true;
  const showOutlet = isMax760 ? navigationValues.visible : true;

  return (
    <NavigationContext.Provider
      value={{
        ...navigationValues,
        setNavigationValues,
      }}
    >
      {showMobileSubNavigation && <SettingsMenu menuItems={menuItems} />}

      <Suspense fallback={<CustomBackdrop />}>
        {showOutlet && (
          <OutletWrapper>
            <Outlet />
          </OutletWrapper>
        )}
      </Suspense>
    </NavigationContext.Provider>
  );
};

export default SettingsWrapper;
