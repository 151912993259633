import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type Props = {
  variant: 'desktop' | 'mobile';
};

const MenuWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'variant' })<Props>(
  ({ variant }) => ({
    height: '100%',
    width: variant === 'desktop' ? 312 : '100%',
  }),
);

export default MenuWrapper;
