import { ChangeEvent, ReactElement, Fragment, useState } from 'react';
import {
  FormControl,
  TextField,
  InputProps,
  SxProps,
  InputAdornment,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import { CustomErrorMessage } from 'app/components/CustomErrorMessage';
import { useController, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { CustomLabel } from 'app/components/CustomLabel';
import { ReactComponent as CopyIcon } from 'images/icons/ic-copy.svg';
import { useTranslation } from 'react-i18next';
import { prefixUrlWithHttp } from 'utils/str/prefixUrlWithHttp';
// import { ReactComponent as MinusIcon } from 'images/icons/ic-minus-circle.svg';
// import { ReactComponent as PlusIcon } from 'images/icons/ic-plus-circle.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

type Props = {
  name: string;
  description?: string | null;
  id?: string;
  label?: string | null;
  placeholder?: string | null;
  type?: string;
  sx?: SxProps;
  inputBaseSx?: object;
  typeNumberSx?: object;
  hideError?: boolean;
  InputProps?: InputProps;
  multiline?: boolean;
  rows?: number;
  icon?: ReactElement;
  copyToClipboard?: boolean;
  maxLength?: number;
  color?: any;
  fullWidth?: boolean;
  additionalLabel?: string;
  defaultLabel?: string;
  sxFormControl?: object;
  maxLengthColor?: string;
  disabled?: boolean;
  showOpenInNewTab?: boolean;
  inputRef?: any;
  autoComplete?: string;
  customOnChange?: (
    field: ControllerRenderProps<FieldValues, string>,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
  onBlur?: (field: ControllerRenderProps<FieldValues, string>) => void;
};

export const ControlledInput = ({
  name,
  description,
  label,
  placeholder,
  type,
  sx,
  InputProps,
  hideError = false,
  multiline,
  rows,
  icon,
  id,
  copyToClipboard = false,
  showOpenInNewTab = false,
  inputBaseSx,
  maxLength,
  color,
  fullWidth = true,
  additionalLabel,
  defaultLabel,
  sxFormControl,
  maxLengthColor = 'navyBlue',
  disabled,
  inputRef,
  customOnChange,
  onBlur,
  autoComplete,
}: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    field,
    formState: { errors },
  } = useController({
    name,
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (customOnChange) {
      customOnChange(field, e);
    } else {
      field.onChange(e);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(field.value);
  };

  const handleOpenInNewTab = () => {
    if (!field.value) {
      return;
    }

    const url = field.value;
    const newWindow = window.open(prefixUrlWithHttp(url), '_blank');
    if (newWindow) newWindow.opener = null;
  };

  const characterCount = field.value ? field.value.length : 0;

  const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type;

  return (
    <FormControl fullWidth={fullWidth} sx={{ ...sxFormControl }}>
      <CustomLabel
        label={label}
        id={id}
        name={name}
        description={description}
        additionalLabel={additionalLabel}
      />
      <TextField
        id={id}
        disabled={disabled}
        type={inputType}
        error={!!errors[name]}
        placeholder={placeholder || ''}
        color={color}
        label={defaultLabel}
        inputRef={inputRef}
        autoComplete={autoComplete || 'on'}
        InputProps={{
          ...InputProps,
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ alignItems: multiline ? 'flex-end' : 'center', color: 'navyBlue' }}
            >
              {icon}
            </InputAdornment>
          ),
          endAdornment: (
            <Fragment>
              {copyToClipboard || showOpenInNewTab ? (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', gap: '8px' }}>
                    {showOpenInNewTab && (
                      <Typography
                        style={{
                          cursor: 'pointer',
                          color: 'dodgerBlue',
                          textDecoration: 'underline',
                        }}
                        onClick={handleOpenInNewTab}
                      >
                        {t('openOnNewTab')}
                      </Typography>
                    )}
                    {copyToClipboard && (
                      <IconButton
                        onClick={handleCopyToClipboard}
                        sx={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <CopyIcon />
                      </IconButton>
                    )}
                  </Box>
                </InputAdornment>
              ) : null}
              {type === 'password' && (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </InputAdornment>
              )}
              {maxLength && (
                <Typography
                  sx={{
                    fontSize: '14px',
                    alignSelf: 'flex-start',
                    margin: '10px 4px 0 8px',
                    color: maxLengthColor,
                  }}
                >
                  {`${characterCount}/${maxLength}`}
                </Typography>
              )}
            </Fragment>
          ),
        }}
        sx={{
          '& .MuiInputBase-input': {
            paddingTop: '10px',
            paddingBottom: '10px',
            ...inputBaseSx,
          },
          '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
          ...sx,
        }}
        multiline={multiline}
        rows={rows}
        {...field}
        onBlur={() => onBlur && onBlur(field)}
        onChange={handleOnChange}
      />
      {!hideError && <CustomErrorMessage errors={errors} name={name} />}
    </FormControl>
  );
};
