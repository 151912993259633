import { ReactNode, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'hooks/useScreenSize';
import { useNavigate } from 'react-router-dom';
import { NavigationContext } from 'app/components/Layout/SettingsWrapper/_context/NavigationContext';
import { SettingHeader } from 'app/components/SettingHeader';
import paths from 'app/router/paths';
import MenuWrapper from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_styles/MenuWrapper';
import SettingsMenuSectionItem from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_components/SettingsMenuSectionItem';
import MenuWrapperScrollArea from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_styles/MenuWrapperScrollArea';
import MenuSectionsWrapper from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_styles/MenuSectionsWrapper';
import { Access } from 'hooks/useGuard';

export type SettingMenuItem = {
  icon: ReactNode;
  to: string;
  name: string;
  target?: string;
  access?: Access;
};

export type SettingsMenuSection = {
  title: string;
  links: SettingMenuItem[];
};

type Props = {
  menuItems: SettingsMenuSection[];
};

const SettingsMenu = ({ menuItems }: Props) => {
  const { visible } = useContext(NavigationContext);
  const { t } = useTranslation();
  const { isMax760 } = useScreenSize();
  const showMobileSubNavigation = isMax760 ? !visible : false;
  const variant = showMobileSubNavigation ? 'mobile' : 'desktop';
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  return (
    <MenuWrapper variant={variant}>
      <MenuWrapperScrollArea scrollRef={scrollRef}>
        <SettingHeader
          scrollRef={scrollRef}
          title={t('settingsTitle')}
          withoutSaveButton={true}
          onBack={() => navigate(paths.calendar.reservationList)}
          isBackArrow={true}
          sx={{
            width: '100%',
            display: { xs: 'block', md: 'none' },
          }}
        />

        <MenuSectionsWrapper variant={variant}>
          {menuItems.map((section) => (
            <SettingsMenuSectionItem key={section.title} menuSection={section} />
          ))}
        </MenuSectionsWrapper>
      </MenuWrapperScrollArea>
    </MenuWrapper>
  );
};

export default SettingsMenu;
