import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const LinkContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

export default LinkContent;
