import { ControlledInput } from 'app/components/FormElements/ControlledInput';
import { ReactElement } from 'react';
import { InputProps } from '@mui/material';

interface OwnProps {
  name: string;
  label?: string;
  description?: string | null;
  isCustom?: boolean;
  disabled?: boolean;
  rows?: number;
  isExpanded?: boolean;
  sx?: object;
  placeholder?: string;
  icon?: ReactElement;
  inputProps?: InputProps;
  copyToClipboard?: boolean;
}

export const MuiTextarea = ({
  name,
  label,
  description,
  isCustom = true,
  disabled,
  rows = 3,
  isExpanded = true,
  sx,
  placeholder,
  icon,
  inputProps,
  copyToClipboard = false,
}: OwnProps) => {
  return (
    <ControlledInput
      icon={icon}
      name={name}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      description={description}
      additionalLabel=""
      multiline={isExpanded}
      rows={rows}
      copyToClipboard={copyToClipboard}
      InputProps={{
        ...inputProps,
      }}
      sx={{
        ...(!isExpanded && {
          '.MuiInputBase-input': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }),
        '& .MuiInputBase-root': {
          backgroundColor: isCustom ? 'zircon' : 'unset',
          padding: icon ? '0 4px 0 14px' : '0px 4px',
          alignItems: 'baseline',
          ...sx,
        },
      }}
    />
  );
};
