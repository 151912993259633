import { createContext, Dispatch, SetStateAction } from 'react';

export type INavigationValues = {
  visible: boolean;
};

type NavigationContextProps = {
  visible: boolean;
  setNavigationValues: Dispatch<SetStateAction<INavigationValues>>;
};

export const NavigationContext = createContext<NavigationContextProps>({
  visible: true,
  setNavigationValues: () => {},
});
