import { Typography } from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarItemStyled } from 'app/components/Navigation/NavigationWrapper/_components/SidebarNavigation/_components/SidebarNavigationItem/_styles/SidebarItemStyled';

type Props = {
  to: string;
  name: string;
  icon: ReactNode;
  hasAccess: boolean;
};

const SidebarNavigationItem = ({ to, name, icon, hasAccess }: Props) => {
  const { pathname } = useLocation();
  const isActive = pathname === to || pathname.includes(`${to}/`);

  const handleBlockRedirect: MouseEventHandler = (e) => {
    if (!hasAccess) e.preventDefault();
  };

  return (
    <SidebarItemStyled
      onClick={handleBlockRedirect}
      to={to}
      style={{ textDecoration: 'none', color: 'white' }}
      key={name}
      isActive={isActive}
      disabled={!hasAccess}
    >
      {icon}
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '10px',
          fontWeight: 600,
          maxWidth: '60px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        className="settings-navigation-link"
      >
        {name}
      </Typography>
    </SidebarItemStyled>
  );
};

export default SidebarNavigationItem;
