import { Box, Button, List, Typography } from '@mui/material';
import { StyledDialog } from 'app/components/Modals/StyledDialog';
import { useTranslation } from 'react-i18next';
import { closestCorners, DndContext } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { useDevice } from 'hooks/useDevice';
import React, { useEffect, useState } from 'react';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { boxStyles, titleStyle, buttonStyles } from 'app/components/Modals/OrderModal/_styles';
import { OrderItem } from 'app/components/Modals/OrderModal/_components/OrderItem';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  onSave: (items: number[]) => void;
  items: { id: number | string; name: string }[];
}

export const OrderModal = ({ open, onClose, onSave, items }: OwnProps) => {
  const { t } = useTranslation();
  const [orderedItems, setOrderedItems] = useState<{ id: number | string; name: string }[]>(items);
  const [order, setOrder] = useState<number[]>([]);
  const { isTouchable } = useDevice();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const order: number[] = arrayMove(
        active?.data?.current?.sortable.items,
        active?.data?.current?.sortable.index,
        over?.data?.current?.sortable.index,
      );

      const orderMap: Map<number, number> = new Map(order.map((id, index) => [id, index]));

      const newOrderedItems = [...orderedItems].sort((a, b) => {
        const indexA = orderMap.get(Number(a.id)) ?? -1;
        const indexB = orderMap.get(Number(b.id)) ?? -1;
        return indexA - indexB;
      });

      setOrderedItems(newOrderedItems);
      setOrder(order);
    }
  };

  useEffect(() => {
    setOrderedItems(items);
  }, [onClose]);

  return (
    <StyledDialog open={open} onClose={onClose}>
      <Box sx={boxStyles}>
        <Typography sx={titleStyle}>{t('priorityChange')}</Typography>
        <DndContext
          collisionDetection={closestCorners}
          autoScroll={!!isTouchable}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={orderedItems}>
            <List>
              {orderedItems.map((item) => (
                <OrderItem key={item.id} id={item.id} name={item.name} />
              ))}
            </List>
          </SortableContext>
        </DndContext>

        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            marginTop: '32px',
            justifyContent: 'space-between',
          }}
        >
          <Button variant="outlined" color="secondary" onClick={onClose} sx={buttonStyles}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onSave(order)}
            sx={buttonStyles}
          >
            {t('save')}
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
};
