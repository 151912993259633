import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const SidebarNavigationLinksWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}));

export default SidebarNavigationLinksWrapper;
