import { SettingMenuItem } from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/index';
import { useScreenSize } from 'hooks/useScreenSize';
import { useContext } from 'react';
import { NavigationContext } from 'app/components/Layout/SettingsWrapper/_context/NavigationContext';
import { ReactComponent as ChevronRight } from 'images/icons/ic-chevron-right.svg';
import LinkContent from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_components/SettingsMenuSectionItem/_components/SettingsMenuLink/_styles/LinkContent';
import LinkTextContainer from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_components/SettingsMenuSectionItem/_components/SettingsMenuLink/_styles/LinkTextContainer';
import LinkText from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_components/SettingsMenuSectionItem/_components/SettingsMenuLink/_styles/LinkText';
import MenuLink from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu/_components/SettingsMenuSectionItem/_components/SettingsMenuLink/_styles/MenuLink';
import { useGuard } from 'hooks/useGuard';

type Props = {
  item: SettingMenuItem;
};

const SettingsMenuLink = ({ item }: Props) => {
  const { isMax760 } = useScreenSize();
  const { setNavigationValues } = useContext(NavigationContext);
  const { hasAccess } = useGuard();
  const isDisabled = item.access && !hasAccess(item.access);

  return (
    <MenuLink
      to={item.to}
      target={item.target}
      key={item.name}
      disabled={isDisabled}
      onClick={(e) => {
        isDisabled && e.preventDefault();
        isMax760 && setNavigationValues({ visible: true });
      }}
    >
      {item.icon}

      <LinkContent>
        <LinkTextContainer>
          <LinkText>{item.name}</LinkText>
        </LinkTextContainer>
        <ChevronRight />
      </LinkContent>
    </MenuLink>
  );
};

export default SettingsMenuLink;
