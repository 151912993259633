import { CircularProgress } from '@mui/material';
import Wrapper from 'app/components/Loaders/FullPageLoader/_styles/Wrapper';

const FullPageLoader = () => {
  return (
    <Wrapper>
      <CircularProgress size={60} />
    </Wrapper>
  );
};

export default FullPageLoader;
