import { Box, ButtonBase, Typography } from '@mui/material';
import {
  BookingStatuses,
  BookingStatusGroups,
  disabledBookingStatuses,
  mapNewToOldStatuses,
  Statuses,
} from 'types/reservation';
import { useTheme } from '@mui/material/styles';
import { CloseButton } from 'app/components/NewBookingModal/_components/CloseButton';
import { getStatusColors } from 'utils/data-processors/getStatusColors';
import { snakeToCamelCase } from 'utils/str/snakeToCamelCase';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from 'app/components/FormElements/CustomSelect';
import { eventTypeOptions } from 'app/components/NewBookingModal/_config';
import { muiInputWithBorder } from 'styles/constants/inputStyles';
import { useState } from 'react';
import { useThemeMode } from 'hooks/useThemeMode';
import { useScreenSize } from 'hooks/useScreenSize';
import { BookingGroups } from 'app/components/NewBookingModal/_components/BookingGroups';
import { shouldPayForLateCancellationNoShow } from 'utils/reservation/reservations';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import moment from 'moment-timezone';
import calendarActions from 'redux/actions/calendar';

interface OwnProps {
  setBookingStatus: (status: Statuses) => void;
  selectedStatus: Statuses;
  onClose: () => void;
  selectedStatusGroup: BookingStatuses[];
  selectedGroupName: BookingStatusGroups;
  isNewReservation: boolean;
  setSelectedStatusGroup: (group_statuses: BookingStatuses[]) => void;
  setSelectedGroupName: (groupName: BookingStatusGroups) => void;
}

export const BookingTopBar = ({
  setBookingStatus,
  selectedStatus,
  onClose,
  selectedStatusGroup,
  selectedGroupName,
  isNewReservation,
  setSelectedStatusGroup,
  setSelectedGroupName,
}: OwnProps) => {
  const [eventType, setEventType] = useState(BookingStatuses.EventPrivate);
  const booking = useAppSelector((state) => state.app.booking);
  const settings = useAppSelector((state) => state.app.settings);
  const now = moment().utc().valueOf() + settings.time_and_language.time_zone_offset * 60 * 1000;
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const { t } = useTranslation();
  const { isDarkMode } = useThemeMode();
  const { isMobile } = useScreenSize();

  const selectedStatusGroupOptions = selectedStatusGroup.map((status) => {
    const bookingStatus = mapNewToOldStatuses[status];
    const colors = getStatusColors(bookingStatus, false);
    const disabled = disabledBookingStatuses.includes(status);

    return {
      label: t(snakeToCamelCase(status)),
      value: bookingStatus,
      disabled,
      colors,
    };
  });

  const checkAndSetBookingStatus = (bookingStatus: Statuses) => {
    setBookingStatus(bookingStatus);
    const showLcfModal = shouldPayForLateCancellationNoShow({
      now,
      bookingStatus,
      start_time: booking.start_time,
      late_cancellation: booking?.special_offer?.late_cancellation,
      stripe_payment_data: booking?.stripe_payment_data,
    });

    if (showLcfModal) {
      dispatch(calendarActions.openLateCancellationNoShowPaymentConfirmationModal());
    }
  };

  const selectedStatusGroupToRender = selectedStatusGroup.map((status) => {
    const bookingStatus = mapNewToOldStatuses[status];
    const isDisabled = disabledBookingStatuses.includes(status);

    return (
      <ButtonBase
        key={status}
        disabled={isDisabled}
        onClick={() => checkAndSetBookingStatus(bookingStatus)}
        sx={{
          minHeight: '40px',
          borderRadius: theme.borderRadius.small,
          padding: '4px 8px',
          backgroundColor:
            bookingStatus === selectedStatus
              ? getStatusColors(bookingStatus, false).secondColor
              : 'initial',
          ...(isDisabled && { backgroundColor: 'transparent' }),
          ...(isDarkMode &&
            bookingStatus === selectedStatus &&
            !isDisabled && { backgroundColor: 'zircon' }),
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: 1,
            color:
              bookingStatus === selectedStatus
                ? getStatusColors(bookingStatus, false).firstColor
                : 'comet',
            ...(isDisabled && { color: 'text.disabled' }),
            ...(isDarkMode &&
              bookingStatus === selectedStatus &&
              !isDisabled && { backgroundColor: 'zircon' }),
          }}
        >
          {t(snakeToCamelCase(status))}
        </Typography>
      </ButtonBase>
    );
  });

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        zIndex: 4,
        position: 'sticky',
        backgroundColor: 'linkWater2',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'brandWhite',
          borderRadius: theme.borderRadius.small,
          width: '100%',
          marginBottom: theme.customSpacing.bookingModal,
          display: 'flex',
          padding: { xs: '12px 16px', xl: `12px ${theme.paddings.bookingModal}` },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: theme.customSpacing.bookingModal,
          }}
        >
          {isMobile && (
            <BookingGroups
              setBookingStatus={setBookingStatus}
              isNewReservation={isNewReservation}
              setSelectedStatusGroup={setSelectedStatusGroup}
              selectedGroupName={selectedGroupName}
              setSelectedGroupName={setSelectedGroupName}
            />
          )}
          {selectedGroupName === BookingStatusGroups.Event && (
            <CustomSelect
              startAdornment={<Typography sx={{ color: 'comet' }}>{t('eventType')}</Typography>}
              options={eventTypeOptions}
              value={eventType}
              onChange={(event) => setEventType(event.target.value as BookingStatuses)}
              sx={{
                backgroundColor: 'brandWhite',
                maxWidth: 'fit-content',
                ...muiInputWithBorder,
              }}
            />
          )}
          {isMobile ? (
            <CustomSelect
              options={selectedStatusGroupOptions}
              value={selectedStatus}
              onChange={(event) => checkAndSetBookingStatus(event.target.value as Statuses)}
              sx={{
                maxWidth: 'fit-content',
              }}
            />
          ) : (
            <Box sx={{ maxWidth: '1180px', display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
              {selectedStatusGroupToRender}
            </Box>
          )}
          {/*<BookingStatusMenu setBookingStatus={setBookingStatus} selectedStatus={selectedStatus} />*/}
        </Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start', flex: 1 }}
        >
          <CloseButton onClose={onClose} />
        </Box>
      </Box>
    </Box>
  );
};
