import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  variant: 'desktop' | 'mobile';
};

const MenuSectionsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<Props>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  width: variant === 'desktop' ? 'auto' : '100%',
}));

export default MenuSectionsWrapper;
