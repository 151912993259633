import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type Props = {
  isVisible?: boolean;
};

const SidebarNavigationWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isVisible',
})<Props>(({ theme, isVisible }) => ({
  display: 'none',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: '80px',
  backgroundColor: theme.palette.darkerBlue,
  padding: '12px',
  height: '100%',
  position: 'sticky',
  top: 0,
  [theme.breakpoints.up('sm')]: {
    display: isVisible ? 'flex' : 'none',
  },
}));

export default SidebarNavigationWrapper;
