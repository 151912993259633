import { Box, Dialog, Typography, Button } from '@mui/material';
import { LoadingOverlay } from 'app/components/Overlays/LoadingOverlay';
import { useAppSelector } from 'redux/hooks';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  title: string;
  description: string;
  cancelButtonText: string;
  confirmButtonText: string;
  isLoading?: boolean;
}

const dialogStyles = {
  '& .MuiDialog-container': {
    background: 'rgba(5, 0, 153, 0.2)',
  },
  '& .MuiPaper-root': {
    margin: '16px',
    maxWidth: '650px',
    width: '100%',
    boxShadow: 'none',
  },
};

const boxStyles = {
  padding: { xs: '16px', sm: '40px' },
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

const typographyStyles = {
  title: {
    fontSize: '32px',
    fontWeight: 700,
    wordBreak: 'break-word',
    lineHeight: 1.1,
    marginBottom: '16px',
    textAlign: 'center',
  },
  description: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'comet',
    wordBreak: 'break-word',
    textAlign: 'center',
  },
};

const buttonStyles = {
  width: '100%',
  minHeight: '40px',
};

export const ConfirmationModal = ({
  open,
  onClose,
  onClick,
  title,
  description,
  cancelButtonText,
  confirmButtonText,
  isLoading,
}: OwnProps) => {
  const { is_submit_available, is_submit_pending } = useAppSelector((state) => state.app.app);

  return (
    <Dialog open={open} onClose={onClose} sx={dialogStyles}>
      <Box sx={boxStyles}>
        <LoadingOverlay isLoading={is_submit_pending || isLoading} />
        <Typography sx={typographyStyles.title}>{title}</Typography>
        <Typography sx={typographyStyles.description}>{description}</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            marginTop: '32px',
            justifyContent: 'space-between',
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={!is_submit_available}
            sx={buttonStyles}
          >
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={buttonStyles}
            disabled={!is_submit_available}
            onClick={onClick}
          >
            {confirmButtonText}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
