import { Box } from '@mui/material';
import { CustomCard } from 'app/components/NewBookingModal/_components/CustomCard';
import { Heading } from 'app/components/Heading';
import { useAppSelector } from 'redux/hooks';
import { useTranslation } from 'react-i18next';

export const WidgetAdditionalFields = () => {
  const { widget_fields } = useAppSelector((state) => state.app.booking);
  const { t } = useTranslation();
  if (!widget_fields.length) return null;

  return (
    <Box>
      <Heading sx={{ marginBottom: 1.5 }}>{t('widgetFields')}</Heading>
      <CustomCard sx={{ borderRadius: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px' }}>
          {widget_fields.map((field) => (
            <div key={field.key}>
              {t(field.key)}: {field.value}
            </div>
          ))}
        </Box>
      </CustomCard>
    </Box>
  );
};
