export const boxStyles = {
  padding: { xs: '16px', sm: '40px' },
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};

export const buttonStyles = {
  width: '100%',
  minHeight: '40px',
};

export const titleStyle = {
  fontSize: '26px',
  fontWeight: 700,
  wordBreak: 'break-word',
  lineHeight: 1.1,
  marginBottom: '18px',
  textAlign: 'center',
};
