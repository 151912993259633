import { useTranslation } from 'react-i18next';
import { Box, SelectChangeEvent } from '@mui/material';
import { Heading } from 'app/components/Heading';
import { MuiSelect } from 'app/components/FormElements/MuiSelect';
import { ReactComponent as ClockIcon } from 'images/icons/ic-clock.svg';
import { bookingFormFieldNames } from 'app/components/NewBookingModal/_config';
import { useAppSelector } from 'redux/hooks';
import { selectCurrentOpeningHours } from 'redux/selectors/openingHours';
import { useEffect, useMemo, useState } from 'react';
import { inRange, range } from 'lodash';
import { createMomentUtc } from 'utils/date-time/createMomentUtc';
import { useFormContext } from 'react-hook-form';
import { useStatus } from 'hooks/useStatus';

export const DateAndTime = () => {
  const { t } = useTranslation();
  const { time } = useAppSelector(selectCurrentOpeningHours);
  const interval = useAppSelector((state) => state.app.settings.reservation.interval);
  const booking_length = useAppSelector(
    (state) => state.app.settings.reservation.default_booking_length,
  );
  const [customBookingLength, setCustomBookingLength] = useState<number | null>(null);

  const { setValue, watch, resetField } = useFormContext();
  const { isReadOnly } = useStatus();

  const timeOptions = useMemo(() => {
    return range(time.start, time.end + interval, interval).map((time) => {
      return {
        label: createMomentUtc(time).format('HH:mm'),
        value: time,
      };
    });
  }, [time.start, time.end]);

  const startTime = watch(bookingFormFieldNames.start_time);
  const endTime = watch(bookingFormFieldNames.end_time);

  const setFirstAvailableStartTime = () => {
    setValue(bookingFormFieldNames.start_time, time.start);
  };

  useEffect(() => {
    if (!startTime) {
      setFirstAvailableStartTime();
    }
    if (!endTime) {
      setValue(bookingFormFieldNames.end_time, time.start + booking_length);
    }

    setCustomBookingLength(endTime - startTime);
  }, []);

  useEffect(() => {
    if (!inRange(startTime, time.start, time.end)) {
      setFirstAvailableStartTime();
      setValue(bookingFormFieldNames.end_time, time.start + booking_length);
    }
  }, [time.start, time.end]);

  const handleStartTimeChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    const duration = customBookingLength || booking_length;
    const newEndValue = value + duration <= time.end ? value + duration : time.end;

    resetField(bookingFormFieldNames.end_time, { keepError: false });
    setValue(bookingFormFieldNames.end_time, newEndValue);
  };

  const handleEndTimeChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > startTime) {
      setCustomBookingLength(value - startTime);
    }
  };

  return (
    <Box>
      <Heading>{t('dateAndTime')}</Heading>
      <Box sx={{ display: 'flex', gap: '8px', flexDirection: { xs: 'column', md: 'row' } }}>
        <MuiSelect
          disabled={isReadOnly}
          name={bookingFormFieldNames.start_time}
          options={timeOptions.slice(0, timeOptions.length - 1)}
          icon={<ClockIcon />}
          translateLabels={false}
          customOnChange={handleStartTimeChange}
          placeholder={createMomentUtc(startTime).format('HH:mm') || t('startTimePlaceholder')}
        />
        {/*<MuiSelect*/}
        {/*  name={bookingFormFieldNames.duration}*/}
        {/*  options={[*/}
        {/*    {*/}
        {/*      label: '2h',*/}
        {/*      value: 120,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  icon={<ClockIcon />}*/}
        {/*  translateLabels={false}*/}
        {/*  placeholder={t('durationPlaceholder')}*/}
        {/*/>*/}
        <MuiSelect
          disabled={isReadOnly}
          name={bookingFormFieldNames.end_time}
          options={timeOptions.slice(1)}
          icon={<ClockIcon />}
          translateLabels={false}
          customOnChange={handleEndTimeChange}
          placeholder={createMomentUtc(endTime).format('HH:mm') || t('endTimePlaceholder')}
        />
      </Box>
    </Box>
  );
};
